/** @jsx jsx */
import { Fragment, useEffect } from 'react'
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'
import { ConsentManager, savePreferences } from '@segment/consent-manager'
import inEU from '@segment/in-eu'
import inRegions from '@segment/in-regions'

/**
 * This file integrates Segment's consent-manager to protect our visitors privacy.
 *
 * See https://github.com/segmentio/consent-manager
 */
const ConsentManagerWrapper = ({ children }) => {
  const getSegmentWriteKey = () => {
    return process.env.NODE_ENV === 'production' &&
      process.env.CONTEXT === 'production'
      ? process.env.GATSBY_SEGMENT_PRODUCTION_WRITE_KEY
      : process.env.GATSBY_SEGMENT_DEV_WRITE_KEY
  }

  const writeKey = getSegmentWriteKey()

  const bannerContent = (
    <span>
      We use cookies (and other similar technologies) to collect data to improve
      your experience on our site.
      <br />
      By using our website, you’re agreeing to the collection of data as
      described in our{' '}
      <Link to="/privacy-policy" sx={{ textDecoration: 'underline' }}>
        Website Data Collection Policy
      </Link>
      .
    </span>
  )

  const bannerActionsBlock = ({ acceptAll, denyAll }) => {
    return (
      <div id="consentManager">
        <button
          type="button"
          onClick={acceptAll}
          sx={{
            backgroundColor: '#014701',
            color: '#5ed6c2',
            mr: '5px',
            padding: '5px 10px',
            border: 'none',
            borderRadius: '2px',
            cursor: 'pointer',
          }}
        >
          Allow all
        </button>
        <button
          type="button"
          onClick={denyAll}
          sx={{
            backgroundColor: '#014701',
            color: '#5ed6c2',
            padding: '5px 10px',
            border: 'none',
            borderRadius: '2px',
            cursor: 'pointer',
          }}
        >
          Deny all
        </button>
      </div>
    )
  }

  const preferencesDialogContent = (
    <div id="consentManagerDialog">
      <p>
        We use data collected by cookies and JavaScript libraries to improve
        your browsing experience, analyze site traffic, deliver personalized
        advertisements, and increase the overall performance of our site.
      </p>
      <p>
        By using our website, you’re agreeing to our{' '}
        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
          Website Data Collection Policy
        </a>
        .
      </p>
      <p>
        The table below outlines how we use this data by category. To opt out of
        a category of data collection, select “No” and save your preferences.
      </p>
    </div>
  )

  const cancelDialogContent = (
    <div>
      Your preferences have not been saved. By continuing to use our website,
      you’re agreeing to our{' '}
      <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
        Website Data Collection Policy
      </a>
      .
    </div>
  )

  // Even though navigator is a property of window,
  // this component is rendered in the browser not SSR

  const hasGPC = navigator.globalPrivacyControl

  const inCA = inRegions(['CA'])

  const getInitialPreferences = () => {
    const generalDefaultPreferences = {
      advertising: true,
      marketingAndAnalytics: true,
      functional: true,
    }
    const caDefaultPreferences = {
      advertising: false,
      marketingAndAnalytics: true,
      functional: true,
    }
    const euDefaultPreferences = {
      advertising: false,
      marketingAndAnalytics: false,
      functional: false,
    }
    const gpcDefaultPreferences = {
      advertising: false,
      marketingAndAnalytics: false,
      functional: false,
    }

    if (hasGPC) {
      return gpcDefaultPreferences
    }
    if (inCA()) {
      return caDefaultPreferences
    }
    if (inEU()) {
      return euDefaultPreferences
    }
    return generalDefaultPreferences
  }

  const shouldRequireConsent = () => {
    const shouldRequireConsentFromEnv =
      process.env.GATSBY_SEGMENT_SHOULD_REQUIRE_CONSENT === 'true'

    if (hasGPC) return false

    return shouldRequireConsentFromEnv || inEU()
  }

  useEffect(() => {
    if (hasGPC) {
      savePreferences({
        destinationPreferences: {
          FacebookPixel: false,
          FacebookPixelServerSide: false,
          GoogleAnalytics: false,
        },
        customPreferences: {
          advertising: false,
          marketingAndAnalytics: false,
          functional: false,
        },
      })
    }
  }, [hasGPC])

  return (
    <Fragment>
      {children}

      <div
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          zIndex: 2147483001,
          '& > div > div': {
            pl: '8px',
            pr: '40px',
          },
          '[title="Close"]': {
            right: '8px',
            left: 'auto',
          },
        }}
      >
        <ConsentManager
          writeKey={writeKey}
          shouldRequireConsent={shouldRequireConsent}
          implyConsentOnInteraction={false}
          bannerContent={bannerContent}
          bannerSubContent="You can manage your preferences here!"
          bannerActionsBlock={bannerActionsBlock}
          bannerHideCloseButton={false}
          bannerAsModal={false}
          bannerTextColor="#014701"
          zIndex={999}
          bannerBackgroundColor="#5ed6c2"
          preferencesDialogTitle="Website Data Collection Preferences"
          preferencesDialogContent={preferencesDialogContent}
          cancelDialogTitle="Are you sure you want to cancel?"
          cancelDialogContent={cancelDialogContent}
          initialPreferences={getInitialPreferences()}
        />
      </div>
    </Fragment>
  )
}

export default ConsentManagerWrapper
