/**@jsx jsx */
import { Heading as StyledHeading, jsx } from 'theme-ui'
import React, { useRef } from 'react'
import { detectLanguageString } from '~/utils/intl/localePrefix'

const Heading = React.forwardRef((props, ref) => {
  const innerRef = useRef()
  const activeRef = ref ?? innerRef
  const lang = detectLanguageString(activeRef?.current?.innerHTML)

  return (
    <StyledHeading ref={activeRef} dir="auto" lang={lang} {...props}>
      {props.children}
    </StyledHeading>
  )
})

export default Heading
