import { useCart, useCheckout } from '@chordcommerce/gatsby-theme-autonomy'
import { useMemo } from 'react'
import useTriggerLocaleWarning from '../use-trigger-locale-warning'
import { localeFromPathname } from '../../../localePrefix'
import { useLocaleContext } from '../../LocaleLogicProvider'
import { useGetAllLocaleLimitations } from '../use-get-all-locale-limitations'
import { getProductBySku } from '~/utils/get-price-sku'

export default function useLocaleCartLogic() {
  const {
    cart,
    modifyLineItem,
    addToCart,
    addSubscription,
    isFetching,
    ...restHook
  } = useCart()
  const { prepareCheckout } = useCheckout()

  const localeItemLimitations = useGetAllLocaleLimitations()

  const { allProducts: products } = useLocaleContext()

  const { triggerShowWarning } = useTriggerLocaleWarning()

  const locale = localeFromPathname().code

  const restrictionAppliesOnLocale =
    localeItemLimitations.hasOwnProperty(locale)

  const totalQuantity = cart?.totalQuantity ?? 0

  const getProductGroupQuantityLimits = productGroup => {
    return localeItemLimitations[locale]?.productGroupQuantityLimits?.[
      `${productGroup}`
    ]
  }

  const getProductGroupBySlug = slug => {
    const product = products.find(product => slug === product.slug)
    if (!product) {
      console.error('No product found for slug', slug)
      return
    }
    return product?.productType?.productGroup ?? 'unattributed'
  }

  const getProductQuantityLimit = slug => {
    const group = getProductGroupBySlug(slug)
    return getProductGroupQuantityLimits(group) ?? Infinity
  }

  const cartProductGroupCount = useMemo(
    () =>
      cart?.lineItems?.reduce((acc, item) => {
        const productGroup = getProductGroupBySlug(item.variant?.slug)

        if (!acc?.[`${productGroup}`]) {
          acc[`${productGroup}`] = 0
        }
        acc[`${productGroup}`] += item.quantity
        return acc
      }, {}) ?? {},
    [cart?.lineItems],
  )

  const getGroupQtyInCart = group => {
    return cartProductGroupCount?.[`${group}`] ?? 0
  }

  const hasGroupExceededQuantityLimit = slug => {
    const group = getProductGroupBySlug(slug)
    return getGroupQtyInCart(group) > getProductQuantityLimit(slug)
  }

  const hasGroupHitQuantityLimit = slug => {
    const group = getProductGroupBySlug(slug)
    return getGroupQtyInCart(group) >= getProductQuantityLimit(slug)
  }

  const willGroupExceedTotalQuantity = (slug, qty) => {
    const group = getProductGroupBySlug(slug)
    return getGroupQtyInCart(group) + qty > getProductQuantityLimit(slug)
  }

  const getGroupAmountExceededNumber = slug => {
    const group = getProductGroupBySlug(slug)
    return Math.max(0, getGroupQtyInCart(group) - getProductQuantityLimit(slug))
  }

  const cartProductGroupExceededMap = useMemo(() => {
    return Object.keys(cartProductGroupCount).reduce((acc, key) => {
      if (cartProductGroupCount[key] > getProductGroupQuantityLimits(key))
        acc[key] =
          cartProductGroupCount[key] - getProductGroupQuantityLimits(key)
      return acc
    }, {})
  }, [cartProductGroupCount])

  // If cart lineitems filter by excluded items is > than limitquantity for locale, throw errors or show messaging, do not allow add to cart or checkout
  async function restrict(cb) {
    const hasCartExceededLimitations =
      cartProductGroupExceededMap &&
      Object.keys(cartProductGroupExceededMap).length

    if (hasCartExceededLimitations) {
      triggerShowWarning()
      throw new Error(
        'The following items exceeded quantity by the amount shown: ',
        cartProductGroupExceededMap,
      )
    }
    if (isFetching) {
      triggerShowWarning()
      throw new Error('Too many requests')
    }
    if (cb instanceof Function) return await cb()
  }

  // Pass in the change in quantity to see if the change will exceed limit before committing action
  async function futureRestrict(cb, totalQtyChange, slug) {
    if (willGroupExceedTotalQuantity(slug, totalQtyChange)) {
      triggerShowWarning()
      throw new Error('Will exceed quantity')
    }
    if (isFetching) {
      triggerShowWarning()
      throw new Error('Too many requests')
    }
    if (cb instanceof Function) return await cb()
  }

  /*
   * ! IMPORTANT: Use this method instead of the one directly from the Chord
   * SDK, this checks any locale quantity restrictions before proceeding to
   * checkout
   *
   * Israel currently legally limits items to 2, this is really important
   */
  const checkoutWithLocaleRestrictions = async () => {
    // Last line of defense before order gets processed through stripe
    if (totalQuantity < 1) throw new Error('Cannot checkout with an empty cart')

    return await restrict(async () => await prepareCheckout())
  }

  const modifyQuantityWithLocaleRestrictions = async (id, qty) => {
    // New quantity replaces old quantity, check if new quantity exceeds old quantity before restricting actions
    const itemToModify =
      cart?.lineItems?.find(lineItem => lineItem.id === id) ?? {}
    const itemQuantity = itemToModify.quantity
    const slug = itemToModify?.variant?.slug

    // Only evaluate restrictions if product is not excluded from count.
    if (itemQuantity < qty) {
      const quantityChange = qty - itemQuantity
      return await futureRestrict(
        async () =>
          await modifyLineItem({
            lineItemId: id,
            attributes: { quantity: qty },
          }),
        quantityChange,
        slug,
      )
    }
    return await modifyLineItem({
      lineItemId: id,
      attributes: { quantity: qty },
    })
  }

  const addToCartWithLocaleRestriction = async (sku, quantity) => {
    const slug = getProductBySku(sku, products)?.slug

    const quantityChange = quantity
    // Only evaluate restrictions if product is not excluded from count.

    return await futureRestrict(
      async () => await addToCart({ sku, quantity }),
      quantityChange,
      slug,
    )
  }

  const subscribeProductWithLocaleRestrictions = async args => {
    const { quantity, sku, interval } = args
    const slug = getProductBySku(sku, products)?.slug

    const quantityChange = quantity

    return await futureRestrict(
      async () =>
        await addSubscription({
          sku,
          quantity,
          interval: {
            length: interval.length,
            unit: Array.isArray(interval.unit)
              ? interval.unit[0]
              : interval.unit,
          },
        }),
      quantityChange,
      slug,
    )
  }

  return {
    getGroupAmountExceededNumber,
    hasGroupExceededQuantityLimit,
    hasGroupHitQuantityLimit,
    checkoutWithLocaleRestrictions,
    modifyQuantityWithLocaleRestrictions,
    addToCartWithLocaleRestriction,
    subscribeProductWithLocaleRestrictions,
    restrictionAppliesOnLocale,
    ...restHook,
    cart,
    isFetching,
  }
}
