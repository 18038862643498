/** @jsx jsx */
import { Global } from '@emotion/react'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useWindowScroll } from 'react-use'
import { jsx } from 'theme-ui'
import useMatchMedia from '../../hooks/utils/use-match-media'

const GorgiasChat = () => {
  const isMobile = useMatchMedia('(max-width: 868px)')
  const [hide, setHide] = React.useState(false)

  useLayoutEffect(_ => {
    window.GORGIAS_CHAT_APP_ID = '10468'
    window.GORGIAS_CHAT_BASE_URL = 'us-east1-898b.production.gorgias.chat'
    window.GORGIAS_API_BASE_URL = 'config.gorgias.chat'
    var e = new XMLHttpRequest()
    e.open('GET', 'https://config.gorgias.chat/applications/10468', !0)
    e.onload = function (t) {
      if (4 === e.readyState)
        if (200 === e.status) {
          var n = JSON.parse(e.responseText)
          if (!n.application || !n.bundleVersion)
            throw new Error(
              'Missing fields in the response body - https://config.gorgias.chat/applications/10468',
            )
          if (
            ((window.GORGIAS_CHAT_APP = n.application),
            (window.GORGIAS_CHAT_BUNDLE_VERSION = n.bundleVersion),
            n && n.texts && (window.GORGIAS_CHAT_TEXTS = n.texts),
            n &&
              n.sspTexts &&
              (window.GORGIAS_CHAT_SELF_SERVICE_PORTAL_TEXTS = n.sspTexts),
            !false)
          ) {
            var r = document.createElement('script')
            r.setAttribute('defer', !0)
            r.setAttribute('id', 'gorgias-chat-widget-install-v3')
            r.src =
              'https://config.gorgias.chat/bundle-loader/01GYCC4QTHQNFXZY2NC3W7CVCF'
            document.body.appendChild(r)
          }
        } else
          console.error(
            'Failed request GET - https://config.gorgias.chat/applications/10468',
          )
    }
    e.onerror = function (_) {
      console.error(_)
    }
    e.send()
  }, [])

  useEffect(() => {
    const isCart =
      typeof window !== 'undefined' && window.location.pathname.includes('cart')
    setHide(isCart && isMobile)
  })
  const { y } = useWindowScroll()

  const shouldJump =
    (typeof window !== 'undefined' &&
      window.location.pathname.includes('products')) ||
    window.location.pathname.includes('bundles')

  let transform = `translateY(-${y > 200 && shouldJump ? 80 : 10}px)`

  return (
    <>
      <Global
        styles={{
          // '#usntA40Toggle.usntA40Dark a#usntA40Link, #usntA40Toggle.usntA40Dark i#usntA40Icon':
          //   {
          //     // backgroundColor: '#8d8c86 !important',
          //     // borderColor: '#8d8c86 !important',
          //   },
          '#usntA40Toggle': {
            bottom: '0px !important',
            transition: 'transform 0.5s ease',
            transform: 'translateY(-18vh)',
          },
        }}
      />
      <div
        sx={{
          zIndex: 900,
          position: 'fixed',
          display: hide ? 'none' : 'block',
          iframe: {
            transform,
            transition: 'transform .15s ease',
          },
        }}
        id="gorgias-chat-container"
      ></div>
    </>
  )
}
export default GorgiasChat
export const wrapPageElement = ({ element }) => (
  <GorgiasChat>{element}</GorgiasChat>
)
