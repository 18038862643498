import React, { createContext, useContext, useMemo } from 'react'
import { localeFromPathname } from '../localePrefix'
import useCatalogByLocale from '../../../hooks/graphql/queries/intlQueries/useCatalogByLocale'
import WarningLocaleModal from '../../../components/IntlComponents/WarningLocaleModal/WarningLocaleModal'
import useShowLocaleWarning from './internal-hook/use-show-warning'
import useFirstLandLocaleWarning from '~/hooks/graphql/queries/intlQueries/use-first-land-locale-warning'
import useLocaleWarning from '~/hooks/graphql/queries/intlQueries/use-locale-warning'
import useUpdateCartLocaleMetadata from './internal-hook/use-update-cart-locale-metadata'

const initValues = {
  allKits: [],
  allProducts: [],
  productsAndKitsByLocale: [],
  triggerShowWarning: false,
  resetWarning: () => {},
}

const LocaleContext = createContext(initValues)

const LocaleLogicProvider = ({ children }) => {
  const { showWarning, showFirstWarning, triggerShowWarning, resetWarning } =
    useShowLocaleWarning()
  useUpdateCartLocaleMetadata()

  const locale = localeFromPathname().code
  const {
    title: firstWarningTitle,
    description: firstWarningDescription,
    ctaButton: firstWarningCtaButton,
  } = useFirstLandLocaleWarning(locale)
  const { title, description, ctaButton } = useLocaleWarning(locale)

  const catalog = useCatalogByLocale(locale)

  const allProducts =
    useMemo(
      () =>
        catalog.collections
          .flatMap(collection => collection.products)
          .filter(Boolean),
      [catalog],
    ) ?? []
  const allKits =
    useMemo(
      () =>
        catalog.collections
          .flatMap(collection => collection.kits)
          .filter(Boolean),
      [catalog],
    ) ?? []
  const productsAndKitsByLocale = useMemo(
    () => [...allProducts, ...allKits],
    [allProducts, allKits],
  )

  const returnValues = {
    allKits,
    allProducts,
    productsAndKitsByLocale,
    triggerShowWarning,
    resetWarning,
  }
  return (
    <LocaleContext.Provider value={returnValues}>
      {/* First land warning */}
      <WarningLocaleModal
        isModalOpen={showFirstWarning}
        title={firstWarningTitle}
        body={firstWarningDescription}
        ctaButton={firstWarningCtaButton}
      />
      {/* Action warning */}
      <WarningLocaleModal
        isModalOpen={showWarning}
        dismissEvent={resetWarning}
        title={title}
        body={description}
        ctaButton={ctaButton}
      />
      {children}
    </LocaleContext.Provider>
  )
}

export const useLocaleContext = () => {
  const values = useContext(LocaleContext) ?? {}
  return values
}

export default LocaleLogicProvider
