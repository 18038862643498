import _ from 'lodash'
/**
 *
 * @param {{}} origObj
 * @param {{}} newObj
 * @param {[]} excludedKeys
 * @returns {}
 *
 * Returns diff of objects
 *
 * https://davidwells.io/snippets/get-difference-between-two-objects-javascript
 */
export function difference(origObj, newObj, excludedKeys = []) {
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0
    return _.transform(newObj, function (result, value, key) {
      if (
        !_.isEqual(value, origObj[key]) ||
        excludedKeys.find(exKey => exKey === key)
      ) {
        let resultKey = _.isArray(origObj) ? arrayIndexCounter++ : key
        result[resultKey] =
          _.isObject(value) && _.isObject(origObj[key])
            ? changes(value, origObj[key])
            : value
      }
    })
  }
  return changes(newObj, origObj)
}
