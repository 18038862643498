import React, { useState } from 'react'
const defaultState = {
  message: '',
  setMessage: () => {},
}
const MessageContext = React.createContext(defaultState)

export const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState('')
  return (
    <MessageContext.Provider value={[message, setMessage]}>
      {children}
    </MessageContext.Provider>
  )
}

export default MessageContext
