/**@jsx jsx */
import { Text as StyledText, jsx } from 'theme-ui'
import React, { useRef } from 'react'
import { detectLanguageString } from '~/utils/intl/localePrefix'

const Text = React.forwardRef((props, ref) => {
  const innerRef = useRef()
  const activeRef = ref ?? innerRef
  const lang = detectLanguageString(activeRef.current?.innerHTML)

  return (
    <StyledText ref={activeRef} dir="auto" lang={lang} {...props}>
      {props.children}
    </StyledText>
  )
})

export default Text
