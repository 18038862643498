import { resetUser } from '@chordcommerce/gatsby-theme-autonomy'
import { useEffect, useState } from 'react'
import { localeFromPathname, locales } from '../../../localePrefix'
import { useGetAllLocaleLimitations } from '../../hooks/use-get-all-locale-limitations'
import useLocaleCartLogic from '../../hooks/use-locale-cart-logic'
import { storageAvailable } from '~/utils/local-storage-available'

/**
 *
 * @returns {{showFirstWarning: boolean, showWarning: boolean, triggerShowWarning: () => void, resetWarning: () => void}}
 * Some locales have special restrictions, this hook allows us to find out if a
 * warning was already shown on locale or not. If not we can perform some kind
 * of action
 *
 * true if warning needs to be shown for the first time, false if warning
 * already shown for that locale
 */
function useShowLocaleWarning() {
  const key = 'user_locale_warning_object'
  const [showWarning, setShowWarning] = useState(false)
  const [showFirstWarning, setShowFirstWarning] = useState(false)
  const currentLocaleCode = localeFromPathname().code
  const { restrictionAppliesOnLocale } = useLocaleCartLogic()

  const showLocaleWarning = () => {
    if (!storageAvailable('localStorage')) return

    // List of restricted locales

    // Get locale warning object from local storage
    const userLocaleWarningObject = localStorage.getItem(key)

    if (!userLocaleWarningObject) {
      // If locale object does not exist, set it up
      let obj = {}
      Object.keys(locales).forEach(
        lang =>
          (obj = {
            ...obj,
            [lang]: currentLocaleCode === lang ? true : false,
          }),
      )
      // Set initialValue in the local storage and return true because
      // The item didn't exist yet.
      const initialValue = JSON.stringify(obj)
      localStorage.setItem(key, initialValue)

      if (restrictionAppliesOnLocale) {
        setShowFirstWarning(true)
      }
      return
    }

    const modifieduserLocaleWarningObject = JSON.parse(userLocaleWarningObject)

    const hasSpecialMessagingBeenShown =
      modifieduserLocaleWarningObject[currentLocaleCode]

    if (hasSpecialMessagingBeenShown) return
    // If object exists, return the value before the modification
    if (restrictionAppliesOnLocale) {
      setShowFirstWarning(!hasSpecialMessagingBeenShown)
    }

    // Modify the locale to be true, meaning we've already recieved the warning
    modifieduserLocaleWarningObject[currentLocaleCode] = true

    localStorage.setItem(key, JSON.stringify(modifieduserLocaleWarningObject))
  }

  const resetWarning = () => {
    setShowWarning(false)
  }

  // trigger showWarning by force
  const triggerShowWarning = () => {
    if (!restrictionAppliesOnLocale) return
    setShowWarning(true)
  }
  useEffect(() => {
    showLocaleWarning()
  }, [currentLocaleCode])

  return { showWarning, showFirstWarning, triggerShowWarning, resetWarning }
}

export default useShowLocaleWarning
