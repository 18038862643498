/** @jsx jsx */
import Modal from 'react-responsive-modal'
import { Box, Button, useThemeUI, jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Heading from '~/components/IntlComponents/Heading'
import Text from '~/components/IntlComponents/Text'
import { Link } from 'gatsby'
import { toURL } from '~/utils/intl/localePrefix'

const WarningLocaleModal = ({
  isModalOpen,
  dismissEvent,
  title,
  body,
  ctaButton,
}) => {
  const { theme } = useThemeUI()

  const [isOpen, setIsOpen] = useState(isModalOpen)

  useEffect(() => {
    setIsOpen(isModalOpen)
  }, [isModalOpen])

  const dismiss = () => {
    setIsOpen(false)
    dismissEvent()
  }

  if (!title && !body && !ctaButton) return null

  return (
    <Modal
      aria-labelledby="first-locale-warning"
      aria-describedby="first-locale-warning"
      classNames={{
        overlay: 'customOverlay',
      }}
      styles={{
        modal: {
          borderRadius: '8px',
          border: `2px solid ${theme.colors.secondary}`,
          height: 'fit-content',
          backgroundColor: theme.colors.primary,
        },
        closeIcon: {
          fill: theme.colors.secondary,
        },
      }}
      open={isOpen}
      onClose={dismiss}
      center
    >
      <Box sx={{ padding: '15px', color: theme.colors.secondary }}>
        {title && (
          <Heading
            sx={{
              fontWeight: 900,
              fontSize: '24px',
              lineHeight: '1em',
              color: 'inherit',
              mb: '15px',
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        <div sx={{ mb: '24px' }}>
          <Text
            dangerouslySetInnerHTML={{
              __html: body,
            }}
          />
        </div>
        <div>
          <Button
            as={ctaButton.externalLink || ctaButton.page ? Link : Button}
            to={toURL(
              location.pathname,
              ctaButton.externalLink ?? ctaButton.page?.slug,
            )}
            sx={{ minWidth: ['auto', '320px'] }}
            onClick={dismiss}
          >
            <Text>{ctaButton.text}</Text>
          </Button>
        </div>
      </Box>
    </Modal>
  )
}

WarningLocaleModal.prototypes = {
  body: PropTypes.string,
  dismissButtonText: PropTypes.string,
  dismissEvent: PropTypes.func,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
}

WarningLocaleModal.defaultProps = {
  dismissEvent: () => {},
  isOpen: false,
}

export default WarningLocaleModal
