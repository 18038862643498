import React from 'react'
import { StytchProvider } from '@stytch/nextjs'
import { useAuth } from '@chordcommerce/react-autonomy'

const AuthProvider = ({ children }) => {
  const { serviceClient } = useAuth()
  return <StytchProvider stytch={serviceClient}>{children}</StytchProvider>
}

export default AuthProvider
