import { graphql, useStaticQuery } from 'gatsby'
import { getDataByLocale } from '~/hooks/utils/get-data-by-locale'

const useLocaleWarning = locale => {
  const data = useStaticQuery(
    graphql`
      query localeWarningQuery {
        warning_enUS: contentfulPageSection(
          slug: { eq: "locale-general-warning" }
          node_locale: { eq: "en-US" }
        ) {
          ...PageSectionModalFragment
        }

        warning_enCA: contentfulPageSection(
          slug: { eq: "locale-general-warning" }
          node_locale: { eq: "en-CA" }
        ) {
          ...PageSectionModalFragment
        }

        warning_frCA: contentfulPageSection(
          slug: { eq: "locale-general-warning" }
          node_locale: { eq: "fr-CA" }
        ) {
          ...PageSectionModalFragment
        }

        warning_heIL: contentfulPageSection(
          slug: { eq: "locale-general-warning" }
          node_locale: { eq: "he-IL" }
        ) {
          ...PageSectionModalFragment
        }

        warning_esMX: contentfulPageSection(
          slug: { eq: "locale-general-warning" }
          node_locale: { eq: "es-MX" }
        ) {
          ...PageSectionModalFragment
        }
      }
    `,
  )
  const warningData = getDataByLocale(locale, data, 'warning_')

  return {
    title: warningData?.title || '',
    description: warningData?.description?.childMarkdownRemark?.html || '',
    ctaButton: warningData?.ctaButton || {},
  }
}

export default useLocaleWarning
