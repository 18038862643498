import { useRef } from 'react'
import { useEffect } from 'react'

import React from 'react'

import { useState } from 'react'
import { createContext } from 'react'
import { useContext } from 'react'
import { difference } from '~/utils/get-diff-from-objects'
import useLocaleCartLogic from '~/utils/intl/context/hooks/use-locale-cart-logic'

export const useTikTokHelper = () => {
  const { ttqState } = useContext(TikTokContext) || {}

  const completePayment = async (id, quantity, currency, value) => {
    if (!ttqState) return
    await ttqState.track('CompletePayment', {
      quantity: quantity,
      content_id: id,
      currency: currency,
      value: value,
    })
  }

  const initiateCheckout = async () => {
    if (!ttqState) return
    await ttqState.track('InitiateCheckout')
  }

  const viewContent = async ({ contentType, description, contentId }) => {
    if (!ttqState) return
    await ttqState.track('ViewContent', {
      content_type: contentType,
      description,
      content_id: contentId,
    })
  }

  return { completePayment, initiateCheckout, viewContent }
}

const TikTokContentInitialValue = {
  ttqState: null,
}

const TikTokContext = createContext(TikTokContentInitialValue)

const TikTokEventProvider = ({ children }) => {
  const {
    cart: { data: cartData },
  } = useLocaleCartLogic()

  const { lineItems } = cartData || {}

  const [ttqState, setTtqState] = useState(null)
  const prevCartRef = useRef(cartData)

  useEffect(() => {
    const ttq = window.ttq

    setTtqState(ttq)
    if (!ttq) return
    if (prevCartRef.current?.totalQuantity < cartData?.totalQuantity) {
      const diff = difference(
        prevCartRef.current?.lineItems,
        cartData?.lineItems,
        ['id'],
      )

      const addedData = cartData.lineItems.find(
        lineItem => lineItem?.id === diff?.[0]?.id,
      )

      if (addedData) {
        ttq.track('AddToCart', {
          content_name: addedData.variant?.name,
          content_id: addedData.id,
          price: addedData.price,
          value: cartData.total,
          currency: cartData.currency,
        })
      }
    }

    prevCartRef.current = cartData
  }, [lineItems, cartData])

  return (
    <TikTokContext.Provider value={{ ttqState }}>
      {children}
    </TikTokContext.Provider>
  )
}

export default TikTokEventProvider
