import { useLocaleContext } from '../LocaleLogicProvider'

const useTriggerLocaleWarning = () => {
  const { triggerShowWarning, resetWarning } = useLocaleContext() ?? {}
  return {
    triggerShowWarning,
    resetWarning,
  }
}

export default useTriggerLocaleWarning
