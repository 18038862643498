const languages = ['fr-CA', 'en-CA' /*, 'es-MX', 'he-IL'*/]

const locales = {
  'en-US': 'USA',
  // 'fr-CA': 'Canada – French',
  'en-CA': 'Canada – English',
  //  'he-IL': 'Israel - עברית',
  // 'es-MX': 'Mexico - Español',
}

const currencies = {
  'en-US': 'USD',
  'en-CA': 'CAD',
  'fr-CA': 'CAD',
  // 'he-IL': 'NIS',
  // 'es-MX': 'MXN',
}

function getFirstItemInPath(pathName) {
  return `${pathName}`.split('/').filter(Boolean).shift()
}

const localePrefix = pathname => {
  const actualPathName =
    pathname || (typeof window === 'undefined' ? '' : window.location.pathname)
  const path = getFirstItemInPath(actualPathName)
  return languages.indexOf(path) !== -1 ? `/${path}/` : '/'
}

const localeFromPathname = pathname => {
  const actualPathName =
    pathname || (typeof window === 'undefined' ? '' : window.location.pathname)
  const path = getFirstItemInPath(actualPathName)
  const code = languages.find(v => v === path) || 'en-US'
  return {
    code,
    label: locales[code],
    currency: currencies[code] || 'USD',
  }
}

const toURL = (pathname, url) => {
  const actualUrl = url || pathname // URL is the first argument if no second one is provided
  const actualPathName = url
    ? pathname
    : typeof window === 'undefined'
    ? ''
    : window.location.pathname
  const cleanedUrl =
    actualUrl.indexOf('/') === 0 ? actualUrl.substring(1) : actualUrl

  return `${localePrefix(actualPathName)}${cleanedUrl}`
}

/**
 * @param {string} localeOption Locale to set cookie to.
 * Cookie called nf_country will be saved in user's browser
 * This will allow users to redirect to another locale at will
 * using logic in the _redirects file and logic in the netlify docs
 * (nf_country will take priority over IP when determining a user's country)
 * https://docs.netlify.com/routing/redirects/redirect-options/
 */
const setNetlifyCountryCookie = localeOption => {
  const parsedLocaleCountry =
    localeOption?.toLowerCase().split('-')?.[1] ?? 'us'
  try {
    let date = new Date()
    date.setDate(date.getDate() + 1)
    document.cookie = `nf_country=${parsedLocaleCountry};path=/;expires=${date.toUTCString()}`
  } catch (e) {
    console.log(e, { source: 'nf_country' })
  }
}

/**
 *
 * @param {string} textContent
 * @returns {string} langauge
 * Pass innerHTML to detect whether or not string should be lang="he" or not.
 * Useful for swapping font family for specific language
 */
const detectLanguageString = textContent => {
  const hebrewDetected = /[\u0590-\u05fe]+/gim.test(textContent)
  return hebrewDetected ? 'he' : 'und'
}

/**
 *
 * @returns {string}
 *
 * Gets langauge based on navigator object, (gets preferred language)
 * If navigator object is not available, it returns en-US.
 *
 */
const getRedirectLanguage = () => {
  if (typeof navigator === 'undefined') {
    return 'en-US'
  }

  const lang = navigator && navigator.language

  switch (lang) {
    // Match results from navigator results
    case 'he':
      return 'he-IL'
    case 'es-MX':
      return 'es-MX'
    case 'fr-CA':
      return 'fr-CA'
    case 'en-CA':
      return 'en-CA'
    default:
      return 'en-US'
  }
}

export default localePrefix
export {
  toURL,
  localePrefix,
  localeFromPathname,
  getRedirectLanguage,
  setNetlifyCountryCookie,
  detectLanguageString,
  languages,
  currencies,
  locales,
}
