import { useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import useLocaleCartLogic from '../../hooks/use-locale-cart-logic'
import { localeFromPathname } from '~/utils/intl/localePrefix'

const useUpdateCartLocaleMetadata = () => {
  const { cart, modifyCart } = useLocaleCartLogic()

  // get cart separately here to avoid an infinite loop

  useEffect(() => {
    const updateMetadata = async () => {
      if (!cart) {
        return
      }
      const currentLocale = localeFromPathname(window.location.pathname)
      const expectedMetadata = {
        // IMPORTANT: Do not change this USD value unless we start allowing locale currency. This will break everything.
        currency: 'USD', // || currentLocale.currency,
        locale: currentLocale.code,
      }

      // Note: This is an important function that handles setting up the cart oms channel for both Israel and Mexico locations. This is a temporary solution while shipping for both Israel and Mexico gets sorted.
      function getLocaleChannel(code) {
        let newChannel = process.env.GATSBY_CHORD_OMS_DEFAULT_CHANNEL
        const key = code.toLowerCase()
        switch (key) {
          case 'he-il':
          case 'es-mx':
          case 'en-ca':
            newChannel = process.env.GATSBY_CHORD_OMS_INTERNATIONAL_CHANNEL
            break
          default:
            break
        }
        return newChannel
      }

      let shouldUpdate = Object.keys(expectedMetadata).find(
        key =>
          !cart.metadata ||
          !cart.metadata[key] ||
          cart.metadata[key] !== expectedMetadata[key],
      )
      if (shouldUpdate) {
        await modifyCart({
          attributes: {
            channel: getLocaleChannel(currentLocale.code),
            metadata: {
              ...(cart.metadata || {}),
              ...expectedMetadata,
            },
          },
        })
      }
    }

    updateMetadata()
  }, [cart])
}
export default useUpdateCartLocaleMetadata
