import { graphql, useStaticQuery } from 'gatsby'
import React, { useMemo } from 'react'

import { getDataByLocale } from '~/hooks/utils/get-data-by-locale'

const useCatalogByLocale = locale => {
  // Also keep in mind that the site is relying on product based "disable product in this locale" field
  const data = useStaticQuery(graphql`
    {
      contentfulCatalog_enUS: contentfulCatalog(
        slug: { eq: "catalog" }
        node_locale: { eq: "en-US" }
      ) {
        collections {
          products {
            productType {
              slug
              productGroup
            }
            variants {
              sku
            }
            name
            slug
          }
          kits {
            name
            slug
          }
        }
      }

      contentfulCatalog_enCA: contentfulCatalog(
        slug: { eq: "catalog" }
        node_locale: { eq: "en-CA" }
      ) {
        collections {
          products {
            productType {
              slug
              productGroup
            }
            variants {
              sku
            }
            name
            slug
          }
          kits {
            name
            slug
          }
        }
      }

      contentfulCatalog_frCA: contentfulCatalog(
        slug: { eq: "catalog" }
        node_locale: { eq: "fr-CA" }
      ) {
        collections {
          products {
            productType {
              slug
              productGroup
            }
            variants {
              sku
            }
            name
            slug
          }
          kits {
            name
            slug
          }
        }
      }

      contentfulCatalog_heIL: contentfulCatalog(
        slug: { eq: "catalog" }
        node_locale: { eq: "he-IL" }
      ) {
        collections {
          products {
            productType {
              slug
              productGroup
            }
            variants {
              sku
            }
            name
            slug
          }
          kits {
            name
            slug
          }
        }
      }

      contentfulCatalog_esMX: contentfulCatalog(
        slug: { eq: "catalog" }
        node_locale: { eq: "es-MX" }
      ) {
        collections {
          products {
            productType {
              slug
              productGroup
            }
            variants {
              sku
            }
            name
            slug
          }
          kits {
            name
            slug
          }
        }
      }
    }
  `)

  return useMemo(
    () => getDataByLocale(locale, data, 'contentfulCatalog_'),
    [data, locale],
  )
}

export default useCatalogByLocale
