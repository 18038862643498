import { graphql, useStaticQuery } from 'gatsby'

export const useGetAllLocaleLimitations = () => {
  const data = useStaticQuery(graphql`
    query LocaleLimitations {
      allContentfulProductType(sort: { order: DESC, fields: node_locale }) {
        edges {
          node {
            node_locale
            productGroup
            allowedQuantityPerLocale
          }
        }
      }
    }
  `)

  // return data
  const productGroupLimitationObject =
    data.allContentfulProductType.edges.reduce((acc, value) => {
      if (value.node.productGroup && value.node.allowedQuantityPerLocale)
        return {
          ...(acc ?? {}),
          [`${value.node.node_locale}`]: {
            productGroupQuantityLimits: {
              ...(acc?.[`${value.node.node_locale}`]
                ?.productGroupQuantityLimits ?? {}),
              [`${value.node.productGroup}`]:
                value.node.allowedQuantityPerLocale,
            },
          },
        }
      return acc
    }, {})
  return productGroupLimitationObject ?? {}
}
