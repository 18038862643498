/**
 *
 * @param {"en-US" | "en-CA" | "fr-CA" | "he-IL" | "es-MX" } locale
 * @param {} data
 * @param {string} key
 * @returns {}
 *
 * Function takes staticQuery data, a locale code, raw data, and a key like
 * this someLocaleData_
 *
 * Data from query should be formatted like so:
 * ```{
 * someLocaleData_enUS {
 * ...
 * }
 * someLocaleData_enCA {
 * ...
 * }
 * someLocaleData_frCA {
 * ...
 * }
 * someLocaleData_heIL {
 * ...
 * }
 * ...
 * }```
 *
 * Data matching the locale after the key will be returned.
 */
export function getDataByLocale(locale, data, key) {
  const cleanedUpLocale = `${locale}`.split('-').join('')
  const defaultLocale = 'enUS'
  return data[`${key}${cleanedUpLocale}`] || data[`${key}${defaultLocale}`]
}
